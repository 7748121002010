@use 'sass:math';

$chessBackgroundUrl: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAMElEQVQokWM8f/78fwYswMDAgBGbOBM2QXxgOGhg/P8fayAxXLhwAavEIPQD7TUAAC36CoBkKtP7AAAAAElFTkSuQmCC');
$chessBackgroundUrlSolid: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMEAIAAACJhxfzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH6AgHEQ4C9g3F0gAAADdJREFUKM/tj7ENACAMw1ouyv9PJB+lH4SFBYRny5KbJMmKAACQnVWH+KE9bdt2liRJum3t4dAAHo4Q604dwQgAAAAASUVORK5CYII=');
// mobile variables
$mobile-font-size: 1.6rem;
$mobile-icon-size: 2rem;

:root {
    // ==== Global color variables ====
    --studio-color-primary: var(--ui-color-primary);
    --studio-color-background: var(--ui-color-background);
    --studio-color-background-second: var(--ui-color-background-second);

    --studio-color-surface: var(--ui-color-surface);
    --studio-color-surface-second: var(--ui-color-surface-second);
    --studio-color-surface-hovered: var(--ui-color-surface-hovered);
    --studio-color-surface-selected: var(--ui-color-surface-selected);
    --studio-color-surface-table: var(--ui-color-surface-table);

    --studio-color-border: var(--ui-color-border);
    --studio-color-border-second: var(--ui-color-border-second);
    --studio-color-border-highlight: var(--ui-color-border-highlight);

    --studio-color-text: var(--ui-color-text);
    --studio-color-text-second: var(--ui-color-text-second);
    --studio-color-text-third: var(--ui-color-text-third);
    --studio-color-text-discrete: var(--ui-color-text-discrete);
    --studio-color-text-disabled: var(--ui-color-text-disabled);
    --studio-color-text-inverted: var(--ui-color-text-inverted);
    --studio-color-text-status-green: var(--ui-color-text-status-green);

    --studio-color-second: var(--ui-color-second);
    --studio-color-third: var(--ui-color-third);
    --studio-color-white: var(--ui-color-white);
    --studio-color-black: var(--ui-color-black);

    --studio-color-grey-21: var(--ui-color-grey-21);
    --studio-color-grey-61: var(--ui-color-grey-61);
    --studio-color-grey-71: var(--ui-color-grey-71);
    --studio-color-grey-84: var(--ui-color-grey-84);
    --studio-color-grey-86: var(--ui-color-grey-86);
    --studio-color-grey-92: var(--ui-color-grey-92);
    --studio-color-grey-93: var(--ui-color-grey-93);
    --studio-color-grey-95: var(--ui-color-grey-95);
    --studio-color-grey-96: var(--ui-color-grey-96);
    --studio-color-grey-97: var(--ui-color-grey-97);
    --studio-color-grey-98: var(--ui-color-grey-98);

    --studio-color-alert: var(--ui-color-alert);
    --studio-color-hover: var(--ui-color-hover);
    --studio-color-disabled-second: var(--ui-color-disabled-second);
    --studio-color-focus: var(--ui-color-focus);
    --studio-color-selection: var(--ui-color-selection);

    // ==== Default colors - soon to be depracated in bannerflow-ui - worth replacing====
    --studio-color-blue: var(--default-color-blue);
    --studio-color-blue-light: var(--default-color-blue-light);
    --studio-color-red-light: var(--default-color-red-light);
    --studio-color-red: var(--default-color-red);
    --studio-color-white-off-light: var(--default-color-white-off-light);
    --studio-color-black-off: var(--default-color-black-off);
    --studio-color-grey-light: var(--default-color-grey-light);
    --studio-color-grey-semidark: var(--default-color-grey-semidark);
    --studio-color-grey-dark: var(--default-color-grey-dark);
    --studio-color-grey-semilight: var(--default-color-grey-semilight);
    --studio-color-grey: var(--default-color-grey);
    --studio-color-text-secondary: var(--default-secondary-text-color);
    --studio-color-active: var(--default-active-color);

    // ==== Static colors ====
    --studio-color-static-deep-red: var(--ui-static-color-deep-red);
    --studio-color-static-black-off-semi: var(--ui-static-color-black-off-semi);
    --studio-color-static-black: var(--ui-static-color-black);

    // === Studio additional colors ====
    --studio-color-additional-salmon: var(--ui-color-additional-salmon);
    --studio-color-additional-aqua: var(--ui-color-additional-aqua);
    --studio-color-additional-sky: var(--ui-color-additional-sky);
    --studio-color-additional-purple: var(--ui-color-additional-purple);
    --studio-color-additional-coral: var(--ui-color-additional-coral);
    --studio-color-additional-olive: var(--ui-color-additional-olive);
    --studio-color-additional-yellow: var(--ui-color-additional-yellow);
    --studio-color-additional-almond: var(--ui-color-additional-almond);

    // === Studio status colors ====
    --studio-color-status-progress: var(--ui-color-border-highlight);
    --studio-color-status-review: var(--ui-color-additional-yellow);
    --studio-color-status-approved: var(--ui-color-text-status-green);
    --studio-color-status-not-approved: var(--ui-color-additional-coral);

    // === Studio transparent colors ===
    --studio-color-transparent: transparent;
    --studio-color-transparent-primary-04: rgba(27, 117, 221, 0.4);

    $transparent-blacks: (
        75: 75,
        40: 40,
        20: 20,
        15: 15,
        10: 10
    );

    $transparent-whites: (
        95: 95,
        75: 75,
        50: 50,
        30: 30
    );

    @each $key, $value in $transparent-blacks {
        --studio-color-transparent-black-#{$key}: rgba(0, 0, 0, #{math.div($value, 100)});
    }

    @each $key, $value in $transparent-whites {
        --studio-color-transparent-white-#{$key}: rgba(255, 255, 255, #{math.div($value, 100)});
    }
}

// The minimum dimensions at which our layout will change,
// adapting to different screen sizes, for use in media queries
// https://github.com/ionic-team/ionic-framework/blob/main/core/src/themes/ionic.globals.scss#L30
// maybe also needs a gap calculation for smooth breaking https://github.com/jgthms/bulma/blob/master/sass/utilities/initial-variables.sass#L53
// extend this when needed
$screen-breakpoints: (
    mobile: 576px,
    tablet: 767px,
    // bulma uses 769px
    desktop: 992px,
    fullhd: 1344px
) !default;
