@use '@angular/material' as mat;
@use 'variables' as *;
@use 'display' as *;

@include mat.elevation-classes();
@include mat.app-background();

$my-app-primary: mat.m2-define-palette(mat.$m2-grey-palette, A200, A100, A400, A100);
$my-app-accent: mat.m2-define-palette(mat.$m2-gray-palette);
$my-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-app-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $my-app-primary,
            accent: $my-app-accent,
            warn: $my-app-warn
        )
    )
);

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@include mat.elevation-classes();
@include mat.app-background();

@include mat.form-field-theme($my-app-theme);
@include mat.select-theme($my-app-theme);
@include mat.chips-theme($my-app-theme);

mat-tab-group {
    --mat-tab-header-divider-height: 0px;
}

html {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: var(--default-font-family);
    line-height: 1.33;
    font-size: 62.5%;
    color: var(--studio-color-text);
    font-weight: var(--default-font-weight);
}

body {
    height: 100%;
    font-size: 1.1rem;
    margin: 0;
    background: var(--studio-color-background);
    overflow: hidden;

    .ui-scrollbar {
        cursor: auto;
    }
}

div {
    user-select: none;
}

.cdk-overlay-pane {
    &.no-min-width {
        min-width: 0px !important;
    }

    &.arrow-top {
        margin-top: 0px !important;
    }
}
