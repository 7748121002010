@use 'mixins' as *;
@use 'sass:map' as map;

// Display
// --------------------------------------------------
// Modifies display of a particular element based on the given classes

// Adds hidden classes
@each $breakpoint in map.keys($screen-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $screen-breakpoints);

    @include media-breakpoint-up($breakpoint, $screen-breakpoints) {
        // Provide `hidden-{bp}-up` classes for hiding the element based on the breakpoint
        .hidden#{$infix}-up {
            display: none !important;
        }
    }

    @include media-breakpoint-down($breakpoint, $screen-breakpoints) {
        // Provide `hidden-{bp}-down` classes for hiding the element based on the breakpoint
        .hidden#{$infix}-down {
            display: none !important;
        }
    }
}
