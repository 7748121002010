@use 'variables' as *;
@use 'sass:list' as list;
@use 'sass:map' as map;
@forward 'variables';

// Get the key from a map based on the index
@function index-to-key($map, $index) {
    $keys: map.keys($map);

    @return list.nth($keys, $index);
}

// Breakpoint Mixins
// ---------------------------------------------------------------------------------

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
// The map defined in the `$screen-breakpoints` global variable is used as the `$breakpoints` argument by default.

// ---------------------------------------------------------------------------------

// Minimum breakpoint width. Null for the biggest (last) breakpoint.
//
//    >> breakpoint-min(mobile, (mobile: 768px, desktop: 992px, fullhd: 1344px))
//    768px
@function breakpoint-min($name, $breakpoints: $screen-breakpoints) {
    $min: map.get($breakpoints, $name);

    @return if($name != index-to-key($breakpoints, 1), $min, null);
}

// Returns a blank string if largest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(fullhd, (mobile: 768px, desktop: 992px, fullhd: 1344px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(mobile, (mobile: 768px, desktop: 992px, fullhd: 1344px))
//    "-mobile"
@function breakpoint-infix($name, $breakpoints: $screen-breakpoints) {
    @return if(breakpoint-max($name, $breakpoints) == null, '', '-#{$name}');
}

// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $screen-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(mobile)
//    desktop
//    >> breakpoint-next(mobile, (mobile: 768px, desktop: 992px, fullhd: 1344px))
//    desktop
//    >> breakpoint-next(fullhd, (mobile: 768px, desktop: 992px, fullhd: 1344px))
//    ""
@function breakpoint-next(
    $name,
    $breakpoints: $screen-breakpoints,
    $breakpoint-names: map.keys($breakpoints)
) {
    $n: index($breakpoint-names, $name);
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Maximum breakpoint width. Null for the smallest (first) breakpoint.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
//
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(desktop, (mobile: 768px, desktop: 992px, fullhd: 1344px))
//    991.98px
@function breakpoint-max($name, $breakpoints: $screen-breakpoints) {
    $max: map.get($breakpoints, $name);
    @return if($max and $max > 0, $max - 0.02, null);
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $screen-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin mobile-text($font-size: $mobile-font-size) {
    @include media-breakpoint-down(desktop) {
        font-size: $font-size;
        color: var(--ui-color-text);
    }
}
